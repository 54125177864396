<template>
  <Modal
      class="modal-detail-document"
      v-if="isShowDetailModal"
      @closeDialog="handleCloseDetailModal()"
  >
    <template #title>{{detailData.name}}</template>
    <template #body>
      <form action="#">
        <div class="content" style="height: 70vh; overflow-y: scroll;">
          <div v-if="!checkRole('developer') && detailData.fileGroups?.length" class="group-archives pl-7 pb-7 pr-7 pt-2">
            <h2 style="padding: 10px 15px; text-align: center; display: flex; justify-content: center; align-items: center;">
              <span class="cursor-pointer">Документы</span>
            </h2>
            <div
                style="border: 1px solid #919191; border-radius: 15px; padding: 10px 15px; margin-bottom: 25px;"
                v-for="(fileGroupBlocks, index) in detailData.fileGroups"
                v-if="isShowAgreedDoc"
                :key="index"
            >
              <div style="margin-bottom: 10px; padding: 10px 0 10px 0;">
                <span style="background: aliceblue; padding: 10px 15px; border-radius: 15px;">
                  <label
                      :for="'doc-type-files-block-all-' + index"
                      class="font-medium">
                    {{fileGroupBlocks.groupName}}
                  </label>
                </span>
              </div>
              <div>
                <div v-for="(fileBlock, indexFileBlock) in fileGroupBlocks.data" :key="indexFileBlock">
                  <div class="grid gap-4 mb-4 sm:grid-cols-2 p-4" :class="{'bg-[#effbe3] border-[#effbe3]': true, 'border': true}">
                    <div class="sm:col-span-1">
                      <div v-if="fileBlock.value.files.length" class="mt-4">
                        <div v-for="file in fileBlock.value.files" class="mb-3">
                          <div class="flex">
                            <a :href="file.src" target="_blank" class="display-block w-48 whitespace-nowrap text-ellipsis overflow-hidden mr-4 hover:text-blue-600" :title="file.original_name">{{ file.original_name }}<div class="text-xs">{{ moment(file.created_at).format('DD.MM.YYYY HH:mm') }}</div></a>
                            <span class="text-xs text-gray-400 mr-2">{{ humanFileSize(file.size) }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="flex items-center space-x-4">
          <button @click="handleCloseDetailModal()" type="button"
                  class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            {{ $t('common.cancel') }}
          </button>
        </div>
      </form>
    </template>
  </Modal>

  <div class="grid grid-cols-1 px-7 pt-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">{{ $t('common.documentFlow') }}</h1>
          </div>
          <div class="flex-shrink-0" v-if="checkRole('developer')">
            <router-link :to="{name: 'RegistryEdit', params: {id: 0}}" class="p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100">&plus; {{ $t('document.add') }}</router-link>
          </div>
        </div>

        <div class="flex w-full justify-between">
          <div class="flex-col p-7 w-1/3">
            <form @submit.prevent="searchEntity()" action="#" method="GET">
              <label for="top-bar-search" class="sr-only">{{ $t('common.search') }}</label>
              <div class="relative w-full">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="search"
                       type="text" id="top-bar-search"
                       class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5"
                       :placeholder="$t('common.search')">
              </div>
            </form>
          </div>
          <div class="flex-col p-7 w-2/3">
            <div class="flex w-full justify-end">
              <div class="flex-col w-1/3 mr-4" v-if="!checkRole(['developer'])">
                <input-select-lite v-model="developerType" :options="developerTypes" :placeholder="$t('document.byDeveloperType')"/>
              </div>
              <div class="flex-col w-1/3 mr-4" v-if="!checkRole(['developer'])">
                <input-select-lite v-model="developer" :options="developers.filter(item => !developerType || item.type_id === parseInt(developerType))" :placeholder="$t('document.byDeveloper')"/>
              </div>
              <div class="flex-col w-1/3">
                <input-select-lite v-model="status" :options="statusList" :placeholder="$t('document.byStatus')"/>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="flex w-full justify-between items-center border-t">
            <div class="flex-col p-7 w-1/2">
              <template>
                <div v-if="entityStore.paginationAgreed">
                  <span class="text-gray-500">{{ $t('common.found') }}</span> {{ entityStore.paginationAgreed.total }}
                </div>
              </template>
            </div>
            <div class="flex-col p-7 w-1/2 text-right">
              <nav v-if="entityStore.paginationAgreed && entityStore.paginationAgreed.lastPage > 1">
                <ul class="inline-flex -space-x-px">
                  <li v-for="(link, index) of entityStore.paginationAgreed.links">
                    <a v-if="link.url" href="#"
                       @click.prevent="entityPaginate(link.url, true)"
                       :class="classInputPagination(index, link.active)"
                       class="px-3 py-2 border border-gray-300">
                      <span v-html="link.label"></span>
                    </a>
                    <a v-else
                       :class="classInputPagination(index, link.active)"
                       class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                      <span v-html="link.label"></span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="overflow-x-auto">
              <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden shadow">
                  <spinner v-if="loadData"/>
                  <div class="agreed-data">
                    <h2 style="padding: 10px 15px; text-align: center; display: flex; justify-content: center; align-items: center;">
                      <span class="cursor-pointer" @click="isShowAgreedDoc=!isShowAgreedDoc">Согласованные документы</span>
                      <button
                          style="border-radius: 50%; background: aliceblue; margin-left: 5px;"
                          @click="isShowAgreedDoc=!isShowAgreedDoc"
                          type="button"
                          class="hover:opacity-60 h-5">
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

                          <path v-if="!isShowAgreedDoc" d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill="#0F0F0F"/>
                          <path v-else d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z" fill="#0F0F0F"/>
                        </svg>
                      </button>
                    </h2>
                    <div v-if="isShowAgreedDoc" class="agreed-data-content">
                      <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                        <thead class="bg-gray-100">
                        <tr>
                          <th scope="col" class="p-4" v-if="allowDeleteEntity">
                            <div class="flex items-center">
                              <input v-model="checkboxAllSelected"
                                     @click="selectAllCheckbox"
                                     id="checkbox-all" type="checkbox"
                                     class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                            </div>
                          </th>
                          <th scope="col" class="p-4 text-left font-light text-gray-400">
                            {{ $t('document.developerType') }}
                          </th>
                          <th scope="col" class="p-4 text-left font-light text-gray-400">
                            {{ $t('document.developerName') }}
                          </th>
                          <th scope="col" class="p-4 text-left font-light text-gray-400">
                            {{ $t('document.documentName') }}
                          </th>
                          <th scope="col" class="p-4 text-left font-light text-gray-400">
                            <span>{{ $t('document.created') }}</span>
                          </th>
                          <th scope="col" class="p-4 text-left font-light text-gray-400">
                            <span>{{ $t('document.updated') }}</span>
                          </th>
                          <th scope="col" class="p-4 text-left font-light text-gray-400">
                            {{ $t('document.status') }}
                          </th>
                          <th scope="col" class=""></th>
                        </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">

                        <tr v-for="entity of entityStore.entitiesAgreed" :key="entity.id" :class="{'bg-neutral-100 opacity-50': entity.isDeleted, 'bg-blue-50': entity.isNotRead}" class="hover:bg-gray-100" @dblclick="$router.push({name: 'RegistryEdit', params: {id: entity.id}})">
                          <td class="p-4 w-4" v-if="allowDeleteEntity">
                            <div class="flex items-center">
                              <input v-model="entityIds" :value="entity.id" aria-describedby="checkbox-1" type="checkbox"
                                     class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                            </div>
                          </td>
                          <td class="p-4">
                            <p class="text-base font-medium text-gray-900">{{ entity.developerType?.name }}</p>
                          </td>
                          <td class="p-4">
                            <p class="text-base font-medium text-gray-900">{{ entity.developer?.name }}</p>
                          </td>
                          <td class="p-4">
                            <p class="text-base font-medium text-gray-900">
                              <span>{{ entity.name }}</span>
                            </p>
                          </td>
                          <td class="p-4">
                            <p class="text-base font-medium text-gray-900">{{ moment(entity.created_at).format('DD.MM.YYYY') }}</p>
                          </td>
                          <td class="p-4">
                            <p class="text-base font-medium text-gray-900">{{ moment(entity.updated_at).format('DD.MM.YYYY') }}</p>
                          </td>
                          <td class="p-4">
                            <p class="text-base font-medium text-gray-900">
                              <span class="px-4 py-1 rounded-xl whitespace-nowrap" :class="['bg-status-' + entity.status]">{{ entity.statusName }}</span>
                            </p>
                          </td>
                          <td class="text-right">
                            <div style="display: flex; cursor: pointer;">
                              <div @click="handleShowDetail(entity)" class="py-2 px-3 text-sm font-medium text-center">
                                <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 4.5C7 4.5 2.73 8.11 1.5 12c1.23 3.89 5.5 7.5 10.5 7.5s9.27-3.61 10.5-7.5c-1.23-3.89-5.5-7.5-10.5-7.5zm0 13c-4.31 0-7.97-2.88-9-5.5C4.03 9.88 7.69 7 12 7s7.97 2.88 9 5.5c-1.03 2.62-4.69 5.5-9 5.5z"></path>
                                  <path d="M12 9a3 3 0 100 6 3 3 0 000-6zm0 5a2 2 0 110-4 2 2 0 010 4z"></path>
                                </svg>
                              </div>
                              <router-link :to="{name: 'RegistryEdit', params: {id: entity.id}}" class="py-2 px-3 text-sm font-medium text-center">
                                <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                                  <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                                </svg>
                              </router-link>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <h3 v-if="!loadData && !entityStore.entitiesAgreed.length" class="text-xl text-center p-7">{{ $t('common.notFound') }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between items-center border-t">
          <div class="flex-col p-7 w-1/2">
            <template v-if="entityIds.length">
              <div>
                <button @click.prevent="deleteEntities" v-if="!isRestoreEntity" class="flex px-6 py-4 bg-primary-500 rounded-md text-white hover:bg-primary-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>{{ $t('common.delete') }}</span>
                </button>
                <button @click.prevent="restoreEntities" v-if="isRestoreEntity" class="flex px-6 py-4 bg-blue-500 rounded-md text-white hover:bg-blue-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>{{ $t('common.restore') }}</span>
                </button>
              </div>
            </template>
            <template v-else>
              <div v-if="entityStore.pagination">
                <span class="text-gray-500">{{ $t('common.found') }}</span> {{ entityStore.pagination.total }}
              </div>
            </template>
          </div>
          <div class="flex-col p-7 w-1/2 text-right">
            <nav v-if="entityStore.pagination && entityStore.pagination.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of entityStore.pagination.links">
                  <a v-if="link.url" href="#"
                     @click.prevent="entityPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

<!--        <div class="flex w-full justify-between">-->
<!--          <div class="flex-col p-7 w-1/3">-->
<!--            <form @submit.prevent="searchEntity()" action="#" method="GET">-->
<!--              <label for="top-bar-search" class="sr-only">{{ $t('common.search') }}</label>-->
<!--              <div class="relative w-full">-->
<!--                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">-->
<!--                  <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>-->
<!--                  </svg>-->
<!--                </div>-->
<!--                <input v-model="search"-->
<!--                       type="text" id="top-bar-search"-->
<!--                       class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5"-->
<!--                       :placeholder="$t('common.search')">-->
<!--              </div>-->
<!--            </form>-->
<!--          </div>-->
<!--          <div class="flex-col p-7 w-2/3">-->
<!--            <div class="flex w-full justify-end">-->
<!--              <div class="flex-col w-1/3 mr-4" v-if="!checkRole(['developer'])">-->
<!--                <input-select-lite v-model="developerType" :options="developerTypes" :placeholder="$t('document.byDeveloperType')"/>-->
<!--              </div>-->
<!--              <div class="flex-col w-1/3 mr-4" v-if="!checkRole(['developer'])">-->
<!--                <input-select-lite v-model="developer" :options="developers.filter(item => !developerType || item.type_id === parseInt(developerType))" :placeholder="$t('document.byDeveloper')"/>-->
<!--              </div>-->
<!--              <div class="flex-col w-1/3">-->
<!--                <input-select-lite v-model="status" :options="statusList" :placeholder="$t('document.byStatus')"/>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                    <tr>
                      <th scope="col" class="p-4" v-if="allowDeleteEntity">
                        <div class="flex items-center">
                          <input v-model="checkboxAllSelected"
                                 @click="selectAllCheckbox"
                                 id="checkbox-all" type="checkbox"
                                 class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                        </div>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        {{ $t('document.developerType') }}
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        {{ $t('document.developerName') }}
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        {{ $t('document.documentName') }}
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        <span>{{ $t('document.created') }}</span>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        <span>{{ $t('document.updated') }}</span>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        {{ $t('document.status') }}
                      </th>
                      <th scope="col" class=""></th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="entity of entityStore.entities" :key="entity.id" :class="{'bg-neutral-100 opacity-50': entity.isDeleted, 'bg-blue-50': entity.isNotRead}" class="hover:bg-gray-100" @dblclick="$router.push({name: 'RegistryEdit', params: {id: entity.id}})">
                    <td class="p-4 w-4" v-if="allowDeleteEntity">
                      <div class="flex items-center">
                        <input v-model="entityIds" :value="entity.id" aria-describedby="checkbox-1" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ entity.developerType?.name }}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ entity.developer?.name }}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">
                        <span>{{ entity.name }}</span>
                      </p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ moment(entity.created_at).format('DD.MM.YYYY') }}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ moment(entity.updated_at).format('DD.MM.YYYY') }}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">
                        <span class="px-4 py-1 rounded-xl whitespace-nowrap" :class="['bg-status-' + entity.status]">{{ entity.statusName }}</span>
                      </p>
                    </td>
                    <td class="text-right">
                      <router-link :to="{name: 'RegistryEdit', params: {id: entity.id}}" class="py-2 px-3 text-sm font-medium text-center">
                        <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                        </svg>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !entityStore.entities.length" class="text-xl text-center p-7">{{ $t('common.notFound') }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRegistryStore} from "../../stores/RegistryStore.js";
import {useInterfaceStore} from "../../stores/InterfaceStore.js";
import {checkRole} from "../../utilits/functions";
import Spinner from "../flowbite/spinner.vue";
import {ref, watch} from "vue";
import moment from 'moment'
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import Modal from "../flowbite/modal.vue";

const interfaceStore = useInterfaceStore();

const isShowAgreedDoc = ref(false);
const isShowDetailModal = ref(false);
const detailData = ref(null);

const entityStore = useRegistryStore();
const isRestoreEntity = ref(false);
const loadData = ref(true);
const loadDataAgreed = ref(true);
const checkboxAllSelected = ref(false);
const entityIds = ref([]);
const search = ref('');
const searchAgreed = ref('');

const allowDeleteEntity = checkRole(['admin']);

const status = ref('');
const statusAgreed = ref('');
const type = ref('');
const typeAgreed = ref('');
const developer = ref('');
const developerAgreed = ref('');
const developerType = ref('');
const developerTypeAgreed = ref('');

const statusList = ref([])
const typeList = ref([])
const documentTypeList = ref([])
const developers = ref([])
const developerTypes = ref([])

const handleShowDetail = (item) => {
  detailData.value = item;
  isShowDetailModal.value = true;
}

const handleCloseDetailModal = () => {
  detailData.value = null;
  isShowDetailModal.value = false;
}

const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

// Первоначальные данные
entityStore.getDirectories().then(() => {
  statusList.value = [...statusList.value, ...entityStore.statusList];
  documentTypeList.value = [...documentTypeList.value, ...entityStore.documentTypeListAll];
  typeList.value = [...typeList.value, ...entityStore.typeList];
  developers.value = [...developers.value, ...entityStore.developers];
  developerTypes.value = [...developerTypes.value, ...entityStore.developerTypes];

  entityStore.getEntities().then(() => {
    loadData.value = false;
  });

  entityStore.getEntities({isAgreed: true}).then(() => {
    loadDataAgreed.value = false;
  });
});

// Галочка выбрать всё
const selectAllCheckbox = () => {
  entityIds.value = [];

  if (!checkboxAllSelected.value) {
    entityStore.entities.forEach((entity, index) => {
      entityIds.value.push(entity.id)
    });
  }
}

// Работа с данными
const entityPaginate = (url, isAgreed = false) => {
  if (url) {
    if (isAgreed) loadDataAgreed.value = true;
    else loadData.value = true;

    let params = {
      search: search.value,
      isAgreed: isAgreed,
      filter: {
      }
    }

    entityStore.getEntities(params, url).then(() => {
      if (isAgreed) loadDataAgreed.value = true;
      else loadData.value = false;
    });
  }
}

const deleteEntities = () => {
  entityStore.deleteEntities(entityIds.value).then(() => {
    entityIds.value = [];
  })
}

const searchEntity = () => {
  loadData.value = true;

  let params = {
    search: search.value,
    filter: {
      type: type.value,
      developer_id: developer.value,
      developer_type_id: developerType.value,
      status: status.value,
    }
  }

  entityStore.getEntities(params).then(() => {
    loadData.value = false;
  });

  let paramsAgreed = {
    search: search.value,
    filter: {
      type: type.value,
      developer_id: developer.value,
      developer_type_id: developerType.value,
      status: status.value,
    },
    isAgreed: true,
  }

  entityStore.getEntities(paramsAgreed).then(() => {
    loadDataAgreed.value = false;
  });
}

const searchEntityAgreed = () => {
  loadDataAgreed.value = true;

  let params = {
    search: searchAgreed.value,
    filter: {
      type: typeAgreed.value,
      developer_id: developerAgreed.value,
      developer_type_id: developerTypeAgreed.value,
      status: statusAgreed.value,
    },
    isAgreed: true,
  }

  entityStore.getEntities(params).then(() => {
    loadDataAgreed.value = false;
  });
}

watch([type, developer, developerType, status], () => {
  searchEntity()
})

watch([typeAgreed, developerAgreed, developerTypeAgreed, statusAgreed], () => {
  searchEntityAgreed()
})

watch(entityIds, () => {
  isRestoreEntity.value = false

  let ids = []

  entityIds.value.forEach((entityId) => {
    ids.push(entityId)
  })

  entityStore.entities.forEach((entity) => {
    if (ids.indexOf(entity.id) !== -1 && entity.isDeleted) {
      isRestoreEntity.value = true
    }
  })
})

const restoreEntities = () => {
  entityStore.restoreEntities(entityIds.value).then(() => {
    entityIds.value = [];
  })
}

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === entityStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});
</script>
